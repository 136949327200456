<template>
  <div>
    <el-card>
      <el-form ref="form" :model="form" :rules="addFormRulue" label-width="110px">
        <el-form-item label="姓名" prop="realName">
          <el-col :span="11">
            <el-input v-model="form.realName" :maxlength="100" show-word-limit></el-input>
          </el-col>
        </el-form-item>
        <!-- <el-form-item label="简介" prop="desc">
          <el-col :span="11">
            <el-input
              v-model="form.desc"
              :maxlength="300"
              show-word-limit
            ></el-input>
          </el-col>
        </el-form-item> -->

        <el-form-item label="公司" prop="company">
          <el-col :span="11">
            <el-input v-model="form.company" :maxlength="50" show-word-limit></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="手机号">
          <el-col :span="11">
            <el-input v-model="form.phone" @change=""></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-col :span="11">
            <el-input v-model="form.email" @change=""></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="企业邮箱" prop="businessEmail">
          <el-col :span="11">
            <el-input v-model="form.businessEmail" @change=""></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="微信" prop="wechat">
          <el-col :span="11">
            <el-input v-model="form.wechat"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="职位" prop="job">
          <el-col :span="11">
            <el-input v-model="form.job"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="form.type" multiple>
            <el-option v-for="(item, index) in config && config.type" :key="index" :label="item" :value="index">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="行业" prop="business">
          <el-select v-model="form.business" multiple :multiple-limit="3">
            <el-option v-for="(item, index) in config && config.business" :key="index" :label="item" :value="index">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="级别" prop="jobLevel">
          <el-select v-model="form.jobLevel">
            <el-option v-for="(item, index) in config && config.userJobLevel" :key="index" :label="item" :value="index * 1">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="城市" prop="city">
          <el-select v-model="form.province" @change="citys(form.province, true)">
            <el-option v-for="(item, index) in province_arr" :key="item.code" :label="item.name" :value="item.code + ''">
            </el-option>
          </el-select>
          <el-select v-model="form.city" class="ml">
            <el-option v-for="(item, index) in city_arr" :key="item.code" :label="item.name" :value="item.code + ''">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公司地址">
          <el-col :span="11">
            <el-input v-model="form.address" @change=""></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-col :span="11">
            <el-input v-model="form.remark" @change=""></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="小助手" prop="waId">
          <el-select v-model="form.waId" multiple :multiple-limit="3">
            <el-option :label="item.name" :value="item.id + ''" v-for="(item, index) in $store.state.NormalList" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动" prop="activityId">
          <el-select v-model="form.activityId" multiple :multiple-limit="3" filterable remote reserve-keyword placeholder="请输入活动名称模糊查询" :remote-method="remoteMethod" :loading="loading" style="width: 480px;">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="嘉宾宣传照" prop="avatar">
          <el-upload
            class="avatar-uploader"
            :action="this.$store.getters.imgUrl"
            accept=".jpg,.jpeg,.png,.gif,.ico,svg"
            :headers="header"
            :show-file-list="false"
            :on-success="handleAvatarSuccess_1"
            :data="action_1"
          >
            <img
              v-if="form.avatar"
              :src="baseurl + yu + form.avatar"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div class="el-upload__tip waring" slot="tip">
              大小不可超过2M,只允许上传jpg,jpeg,png,gif,ico,svg格式的图片
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="资料打包">
          <el-upload
            ref="pdf"
            class="upload-demo"
            :on-progress="beforeAvatarUpload"
            :on-remove="word"
            :action="this.$store.getters.imgUrl"
            accept=".zip , .rar"
            :headers="header"
            :on-success="handleAvatarSuccess"
            :data="action"
            :on-preview="load"
            :limit="1"
            :show-file-list="true"
          >
            <el-button size="small" type="primary" v-if="pdfstaus"
              >点击上传</el-button
            >
            <button
              size="small"
              type="primary"
              :disabled="true"
              v-else
              class="lod"
            >
              加载中
            </button>
            <div class="el-upload__tip waring" slot="tip">
              请上传zip，rar文件（包含宣传物料，logo源文件等内容
            </div>
          </el-upload>
        </el-form-item> -->

        <el-form-item>
          <el-button type="primary" @click="add" v-if="!id">{{
            btn ? '确 定' : 'loading..'
          }}</el-button>
          <el-button type="primary" @click="editadd" v-if="id">{{
            btn ? '修 改' : 'loading..'
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import quillConfig from '../../quill'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      quillOption: quillConfig,
      keysome: '',
      sd: [],
      loading: false,
      options: [],
      // 提交数据
      form: {
        realName: '',
        // desc: '',
        company: '',
        job: '',
        phone: '',
        email: '',
        businessEmail: '',
        wechat: '',
        type: '',
        business: '',
        jobLevel: '',
        city: '',
        province: '',
        address: '',
        remark: '',
        service: '',
        activityId: [],
        // avatar: '',
        city: null,

        province: null,
        document: ''
      },
      ys: false,
      pdfstaus: true,
      // addFormRulue: {
      //   realName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
      //   company: [{ required: true, message: '请输入公司', trigger: 'blur' }],
      //   phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
      //   email: [{ required: true, message: '请输入邮箱', trigger: 'blur' }],
      //   job: [{ required: true, message: '请输入职位', trigger: 'blur' }],
      //   type: [{ required: true, message: '请输入类型', trigger: 'blur' }],
      //   jobLevel: [{ required: true, message: '请输入级别', trigger: 'blur' }],
      //   business: [{ required: true, message: '请输入行业', trigger: 'blur' }],
      //   city: [{ required: true, message: '请输入城市', trigger: 'blur' }],
      //   address: [
      //     { required: true, message: '请输入公司地址', trigger: 'blur' }
      //   ],
      //   year: [{ required: true, message: '请输入年份', trigger: 'blur' }]
      // },
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      btn: true,
      action: {
        action: 'speaker',
        type: 'document'
      },
      action_1: {
        action: 'speaker'
      },
      area: [],
      header: {
        Token: sessionStorage.getItem('token')
      },
      province_arr: [],
      id: null,
      city_arr: []
    }
  },
  methods: {
    word() {
      this.pdfstaus = true
    },
    beforeAvatarUpload() {
      this.pdfstaus = false
    },
    remoteMethod(qu) {
      if (qu != '') {
        this.loading = true
        this.$http
          .get('/admin/Activity/getList', {
            params: {
              page: 1,
              pageSize: 50,
              title: qu,
              eventDate: '',
              publishDate: '',
              publishStatus: '',
              checkStatus: '',
              acId: '',
              order: ''
            }
          })
          .then(({ data: res }) => {
            this.loading = false
            if (res.errorCode == 200) {
              console.log(res.data)
              this.options = []
              res.data.list.forEach(item => {
                this.options.push({ value: item.id, label: item.title })
              })
            } else {
              this.$message.error(res.message)
            }
          })
      }
    },
    getfrom() {
      if (this.$route.query.id) {
        var id = this.$route.query.id
        this.id = id
        this.$http
          .get('/admin/UserGuest/getById?id=' + id)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              // console.log(res);
              this.form = {
                ...res.data,
                activityId:
                  !!res.data.activityId && res.data.activityId.split(','),
                waId: !!res.data.waId && res.data.waId.split(','),
                business: !!res.data.business && res.data.business.split(','),
                 type: !!res.data.type && res.data.type.toString().split(',')
              }
              var eds = res.data.activityId.split(',')
              var act =
                res.data.activityName && res.data.activityName.split(',')
              console.log(act)
              eds.forEach((item, n) => {
                this.options.push({ value: item, label: act && act[n] })
              })
              console.log(this.options)
              // console.log(this.form)
              this.citys(this.form.province)
              this.form.id = id * 1
            } else {
              this.$message.error(res.message)
            }
          })
      }
    },

    load() {
      // console.log(file);
      location.href = this.baseurl + this.yu + this.form.document
    },
    // 确定
    add() {
      this.$refs.form.validate(() => {
        if (this.btn) {
          this.$http
            .post('/admin/UserGuest/add', {
              ...this.form,
              activityId:
                this.form.activityId && this.form.activityId.join(','),
              waId: this.form.waId && this.form.waId.join(','),
              business: this.form.business && this.form.business.join(',')
            })
            .then(({ data: res }) => {
              if (res.errorCode == 200) {
                this.$message.success('操作成功')
                this.$router.push('/user_guest')
              } else {
                this.$message.error(res.message)
              }
              this.btn = true
              // if(res.)
            })
        }
        this.btn = false
      })

      // })
    },
    // 修改
    editadd() {
      this.$refs.form.validate(() => {
        if (this.btn) {
          this.$http
            .post('/admin/UserGuest/edit', {
              ...this.form,
              activityId:
                this.form.activityId && this.form.activityId.join(','),
              waId: this.form.waId && this.form.waId.join(','),
              business: this.form.business && this.form.business.join(',')
            })
            .then(({ data: res }) => {
              if (res.errorCode == 200) {
                this.$message.success(res.message)
                this.$router.push('/user_guest')
                if (this.ys) {
                  this.ys = false
                  sessionStorage.setItem('adf', 'true')
                  this.$store.state.Did = this.form.id
                } else {
                  this.$store.state.counts = 0
                }
              } else {
                this.$message.error(res.message)
              }
              this.btn = true
            })
        }
        this.btn = false
      })
    },
    handleAvatarSuccess_1(res) {
      if (res.errorCode == 200) {
        this.form.avatar = res.data.url
        this.$forceUpdate()
      } else {
        this.$message.error(res.message)
      }
    },
    handleAvatarSuccess(res, file) {
      this.form.document = ''
      if (res.errorCode == 200) {
        this.form.document = res.data.url
        this.$forceUpdate()
        // this.form=[...this.form]
      } else {
        this.$message.error(res.message)
        this.$refs.pdf.clearFiles()
      }
      this.pdfstaus = true
      console.log(file)
      //   console.log(imgurlbase)
    },
    beforeUpload() {
      if (this.pdfstaus) {
        return false
      }
    },
    getea() {
      this.$http.get('/admin/ReportCategory/getList').then(({ data: res }) => {
        if (res.errorCode == 200) {
          this.area = res.data
          console.log(this.area)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    async getp() {
      const jj = await this.$store.dispatch('getProvince')
      this.province_arr = jj.data
    },
    async citys(val, key) {
      if (key) {
        this.form.city = ''
      }
      // console.log(val)
      const jj = await this.$store.dispatch('getCity', val)
      this.city_arr = jj.data
    }
  },

  created() {
    // this.getea()
    this.getfrom()
    this.getp()
  },
  mounted() {
    // console.log(this.area);
    console.log(1)

    if (document.querySelector('.v-modal')) {
      document.querySelector('.v-modal').click()
      this.ys = true
    }
    sessionStorage.setItem('action', 'report')
  },
  computed: {
    ...mapState(['config'])
    // citys() {
    //   return async function(val) {
    //     const jj = await this.$store.dispatch('getCity', val)
    //     return jj.data
    //   }
    // }
  }
}
</script>

<style lang="less" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  height: 178px;
  display: block;
}
.waring {
  color: #fda400;
  line-height: 24px;
}
.ml {
  margin-left: 10px;
}
.lod {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: not-allowed;
  background-color: #fda400;
  // background: #fff;
  border: 1px solid #dcdfe6;
  color: white;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  padding: 8px 15px;
  font-size: 13px;
  border-radius: 4px;
}
</style>
